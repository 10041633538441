<template>
  <div v-if="PackCostFormat.length != 0" class="mt-0" align="center" >
    <v-card :elevation="0" width="793" >
      <v-card-text>
        <!-- ส่วนหัว -->
            <v-row justify="center"  class="mb-2">
                <v-col cols="6" align="left" >
                    <p class="mb-1"><img src="@/assets/logo_color_packhai_warehouse.png" width="50%"></p>
                    <p  class="mb-0 font-weight-bold">{{Namebranch}}</p>
                    <p  class="mb-0">เบอร์โทร : {{telbranch}}</p>
                    <span>{{branchtextaddress}}</span>
                    <span v-if="addressbranch.id != null || addressbranch.id != '' " >
                      <span v-if="addressbranch.district != null || addressbranch.district != '' "> {{addressbranch.district}} </span>
                      <span v-if="addressbranch.amphoe != null || addressbranch.amphoe != '' "> {{addressbranch.amphoe}}</span>
                      <span v-if="addressbranch.province != null || addressbranch.province != '' "> {{addressbranch.province}}</span>
                      <span v-if="addressbranch.zipcode != null || addressbranch.zipcode != '' "> {{addressbranch.zipcode}}</span>
                    </span>
                </v-col>
                <v-col cols="6" align="right">
                    <p class="display-1 mb-1">ใบเสนอราคา/Quotation</p>
                    <span >*ราคานี้ยังไม่รวมภาษีมูลค่าเพิ่ม</span>
                </v-col>
            </v-row>
        <!-- ส่วนร้านค้า -->
            <v-row>
                <v-col align="left" >
                    <p class="mb-2 font-weight-bold">ข้อมูลร้านค้า</p>
                    <p class="mb-1"> <span class="font-weight-bold "> ชื่อร้านค้า : </span> {{addFCName}}</p>
                    <p class="mb-1"> <span class="font-weight-bold ">ที่อยู่ร้านค้า : </span>{{addFCAddress}}
                      <span v-if="addFCAddressCity.id != null || addFCAddressCity.id != '' " >
                        <span v-if="addFCAddressCity.district != null || addFCAddressCity.district != '' "> {{addFCAddressCity.district}} </span>
                        <span v-if="addFCAddressCity.amphoe != null || addFCAddressCity.amphoe != '' "> {{addFCAddressCity.amphoe}}</span>
                        <span v-if="addFCAddressCity.province != null || addFCAddressCity.province != '' "> {{addFCAddressCity.province}}</span>
                        <span v-if="addFCAddressCity.zipcode != null || addFCAddressCity.zipcode != '' "> {{addFCAddressCity.zipcode}}</span>
                      </span>
                    </p>
                    <p class="mb-1"><span class="font-weight-bold ">เบอร์โทร : </span>{{addFCTel}}</p>
                </v-col>
                <!-- <v-col cols="6" align="left">
                    <p>ข้อมูลลูกค้า</p>
                    <p>ชื่อลูกค้า : {{ShopAddressInvoice.Name}}</p>
                    <p>เลขประจำตัวผู้เสียภาษี : {{ShopAddressInvoice.IDCardNumber}}</p>
                    <p>ที่อยู่ลูกค้า : </p>
                    <p>เบอร์โทร : {{ShopAddressInvoice.Phone}}</p>
                </v-col> -->
            </v-row>
            <v-divider class="my-3"></v-divider>

            <!-- อัตราค่าบริการ -->
            <div div v-if="dataShopMonthlyFee.length > 0">
              <p class="title text-left">ค่าบริการรายเดือน</p>
              <div v-for="(i,index) in dataShopMonthlyFee"  v-bind:key="index">
                <v-row row wrap class="mb-2 ml-2 text-left">
                  <v-col cols="4">
                    <p>{{i.ServiceCharge}}</p>
                  </v-col>
                  <v-col cols="3">
                    <p>{{formatMoney(i.Price)}}</p>
                  </v-col>
                  <v-col cols="5">
                    <p>บาท/เดือน</p>
                  </v-col>
                </v-row>
              </div>
            </div>

            <!-- เงินเครดิตขั้นต่ำ -->
            <div v-if="MinimumCredit==1">
              <p class="title text-left">เงินเครดิตขั้นต่ำ</p>
              <!-- <div v-for="(i,index) in dataShopMonthlyFee"  v-bind:key="index"> -->
                <v-row row wrap class="mb-2 ml-2 text-left">
                  <v-col cols="4">
                    <span>ขั้นต่ำ</span>
                  </v-col>
                  <v-col cols="3">
                    <span class="text-left">{{BalanceLimit}}</span>
                  </v-col>
                  <v-col cols="5">
                    <span>บาท</span>
                  </v-col>
                </v-row>
              <p class="text-left " style="color: red;">*ผู้รับบริการจะต้องมีเงินในระบบไม่ต่ำกว่า ขั้นต่ำที่กำหนดไว้ หากยอดต่ำกว่าจำนวนขั้นต่ำ ระบบจะไม่สามารถทำรายการแพ็คสินค้าต่อได้</p>
              <!-- </div> -->
            </div>
            <!-- <div v-else>
                <p class="text-left">ไม่มีขั้นต่ำ</p>
            </div> -->

            <!-- ค่าบริการการแพ็คสินค้า -->
            <div v-for="(i,index) in selectedTransport"  v-bind:key="index" class="text-left">
              <!-- Shopee -->
              <div v-if="i == 8" div style="break-after:page" >
                <p class="title"> อัตราค่าบริการ SHOPEE </p>
                <p class="subtitle-1 font-weight-bold mb-1 ">ค่าบริการการแพ็คสินค้า</p>
                <div v-if="PackCostFormat.SHOPEE.rdoPackCost==1">
                  <v-row row wrap class="mb-2 ml-2">
                    <v-col cols="4" ><span>ราคาแพ็ค</span></v-col>
                    <v-col cols="3"><span>{{PackCostFormat.SHOPEE.PackCost}}</span></v-col>
                    <v-col cols="5"><span>บาท/ออเดอร์</span></v-col>
                  </v-row>
                  <div class="my-3">
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.SHOPEE.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div v-else-if="PackCostFormat.SHOPEE.rdoPackCost==2">
                  <v-data-table
                      :headers="headerBoxCreate"
                      :items="PackCostFormat.SHOPEE.Postbox"
                      :items-per-page="90000000000000000"
                      hide-default-footer
                      class="packhai-border-table-quaotation tr td"
                  >
                    <template v-slot:item.Name="{ item }">
                      <span >{{item.Name}}</span>
                    </template>
                    <template v-slot:item.Size="{ item }">
                      <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                    </template>
                    <template v-slot:item.Price="{ item }">
                      <span >{{formatMoney(item.Price)}}</span>
                    </template>
                    <template v-slot:item.PackCost="{ item }">
                      <span >{{formatMoney(item.PackCost)}}</span>
                    </template>
                  </v-data-table>
                </div>
                <div v-else>
                  <v-row v-for="i in PackCostFormat.SHOPEE.FollowPicesPrice" :key="i.ID" row wrap class="packhai-card-padding py-1">
                      <v-col cols="4">
                        <span >ชิ้นที่ {{i.Start}} - {{i.End == null || i.End == "" ? "เป็นต้นไป"  : i.End}}</span>
                      </v-col>
                      <v-col cols="3">
                        <span>ราคา {{i.Price}}</span>
                      </v-col>
                      <v-col cols="3">
                        <span>บาท/ชิ้น</span>
                      </v-col>
                  </v-row>
                  <div class="my-3">
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.SHOPEE.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div>
                  <p class="subtitle-1 font-weight-bold mb-1">ค่าบริการเชื่อมต่อ</p>
                  <v-row row wrap class="mb-2 ml-2">
                    <v-col cols="4">
                      shopee ร้านแรก :
                     </v-col>
                     <v-col cols="3">
                       {{PackCostFormat.SHOPEE.FirstShopFee}}
                     </v-col>
                     <span class="pl-2">บาท/เดือน</span>
                  </v-row>
                  <v-row row wrap class="mb-2 ml-2">
                    <v-col cols="4">
                      shopee ร้านถัดไป :
                     </v-col>
                     <v-col cols="3">
                       {{PackCostFormat.SHOPEE.SecondShopFee}}
                     </v-col>
                     <span class="pl-2">บาท/เดือน</span>
                  </v-row>
                </div>
              </div>

              <!-- LAZADA -->
              <div v-if="i == 9" div style="break-after:page">
                <p class="title "> อัตราค่าบริการ LAZADA</p>
                <p class="subtitle-1 font-weight-bold mb-1">ค่าบริการการแพ็คสินค้า</p>
                <div v-if="PackCostFormat.LAZADA.rdoPackCost==1">
                  <v-row row wrap class="mb-2 ml-2 ">
                    <v-col cols="4" ><span>ราคาแพ็ค</span></v-col>
                    <v-col cols="3"><span>{{PackCostFormat.LAZADA.PackCost}}</span></v-col>
                    <v-col cols="5"><span>บาท</span></v-col>
                  </v-row>
                  <div class="my-3">
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.LAZADA.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div v-else-if="PackCostFormat.LAZADA.rdoPackCost==2">
                  <v-data-table
                      :headers="headerBoxCreate"
                      :items="PackCostFormat.LAZADA.Postbox"
                      :items-per-page="90000000000000000"
                      hide-default-footer
                      class="packhai-border-table-quaotation tr td"
                  >
                    <template v-slot:item.Name="{ item }">
                    <span >{{item.Name}}</span>
                    </template>
                    <template v-slot:item.Size="{ item }">
                    <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3}} </span>
                    </template>
                    <template v-slot:item.Price="{ item }">
                    <span >{{formatMoney(item.Price)}}</span>
                    </template>
                    <template v-slot:item.PackCost="{ item }">
                    <span >{{formatMoney(item.PackCost)}}</span>
                    </template>
                  </v-data-table>
                </div>
                <div v-else>
                  <v-row v-for="i in PackCostFormat.LAZADA.FollowPicesPrice" :key="i.ID" row wrap class="packhai-card-padding py-1">
                      <v-col cols="4">
                        <span > ชิ้นที่ {{i.Start}} - {{i.End == null || i.End == "" ? "เป็นต้นไป"  : i.End}}</span>
                      </v-col>
                      <v-col cols="3">
                        <span>ราคา {{i.Price}}</span>
                      </v-col>
                      <v-col cols="3">
                        <span>บาท/ชิ้น</span>
                      </v-col>
                    </v-row>
                    <div class="my-3">
                      <v-data-table
                      :headers="headerBoxPrice"
                      :items="PackCostFormat.LAZADA.Postbox"
                      :items-per-page="90000000000000000"
                      hide-default-footer
                      class="packhai-border-table-quaotation tr td"
                    >
                        <template v-slot:item.Name="{ item }">
                          <span >{{item.Name}}</span>
                        </template>
                        <template v-slot:item.Size="{ item }">
                          <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                        </template>
                        <template v-slot:item.Price="{ item }">
                          <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                        </template>
                     </v-data-table>
                    </div>
                </div>
                <div>
                  <p class="subtitle-1 font-weight-bold mb-1">ค่าบริการเชื่อมต่อ</p>
                  <v-row row wrap class="mb-2 ml-2">
                    <v-col cols="4">
                      Lazada ร้านแรก :
                     </v-col>
                     <v-col cols="3">
                       {{PackCostFormat.LAZADA.FirstShopFee}}
                     </v-col>
                     <span class="pl-2">บาท/เดือน</span>
                  </v-row>
                  <v-row row wrap class="mb-2 ml-2">
                    <v-col cols="4">
                      Lazada ร้านถัดไป :
                     </v-col>
                     <v-col cols="3">
                       {{PackCostFormat.LAZADA.SecondShopFee}}
                     </v-col>
                     <span class="pl-2">บาท/เดือน</span>
                  </v-row>
                </div>
              </div>

              <!-- BEST EXPRESS -->
              <div v-if="i == 1" div style="break-after:page">
                <p class="title "> อัตราค่าบริการ BEST EXPRESS </p>
                <p class="subtitle-1 font-weight-bold mb-1" >ค่าบริการการแพ็คสินค้า</p>
                <div v-if="PackCostFormat.BEST.rdoPackCost==1">
                  <v-row>
                    <v-col cols="4">ราคาแพ็ค</v-col>
                    <v-col cols="3">{{PackCostFormat.BEST.PackCost}}</v-col>
                  </v-row>
                  <div class="my-3">
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.BEST.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div v-else-if="PackCostFormat.BEST.rdoPackCost==2">
                  <v-data-table
                    :headers="headerBoxCreate"
                    :items="PackCostFormat.BEST.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                    <template v-slot:item.Name="{ item }">
                      <span >{{item.Name}}</span>
                    </template>
                    <template v-slot:item.Size="{ item }">
                      <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                    </template>
                    <template v-slot:item.PackCost="{ item }">
                      <span >{{formatMoney(item.PackCost)}}</span>
                    </template>
                </v-data-table>
                </div>
                <div v-else>
                  <v-row v-for="i in PackCostFormat.BEST.FollowPicesPrice" :key="i.ID" row wrap class="packhai-card-padding py-1">
                    <v-col cols="4">
                      <span >ชิ้นที่ {{i.Start}} - {{i.End}}</span>
                    </v-col>
                    <v-col cols="3">
                      <span>ราคา {{i.Price}}</span>
                    </v-col>
                    <v-col cols="3">
                        <span>บาท/ชิ้น</span>
                      </v-col>
                  </v-row>
                  <div class="my-3">
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.BEST.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div >
                  <p class="subtitle-1 font-weight-bold mt-4 mb-0"> COD </p>
                  <p v-if="formatCOD.BEST == 0" >ไม่อนุญาตส่งแบบ COD</p>
                  <div v-else >
                    <p class="mb-0"> ค่าบริการ COD : {{TransportBEST_COD_Remote_Return.BEST_COD_Percent}} %</p>
                    <p class="mb-0"> ส่วนลดพิ้นที่ห่างไกล :
                      <span v-if="TransportBEST_COD_Remote_Return.BEST_Remote_Area_Discount_Percent == null || TransportBEST_COD_Remote_Return.BEST_Remote_Area_Discount_Percent ==''"> 0 </span>
                      <span v-else > {{TransportBEST_COD_Remote_Return.BEST_Remote_Area_Discount_Percent}} </span>
                    % </p>
                  </div>
                  <span style="color: red;">
                    <p class="mb-0">** ยอดเงิน COD จะโอนเข้าบัญชีโดยตรง</p>
                    <p class="mb-0 ml-4"> ธนาคารกสิกรไทย ภายใน 1 วัน</p>
                    <p class="mb-0 ml-4"> ธนาคารอื่นๆ ภายใน 3-5 วันทำการ</p>
                  </span>
                </div>
                <p class="subtitle-1 font-weight-bold mt-3 mb-1">ค่าขนส่ง</p>
                <div>
                    <v-data-table
                    :headers="header.BEST"
                    :items="DeliveryPice.BEST"
                    :items-per-page="itemsPerPageฺBEST"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                  <template v-for="header in header.BEST" v-slot:[`header.${header.value}`]="{ header }" >
                    <v-tooltip :key="header.value" v-slot:activator="{ on }" >
                      <span v-on="on" >{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item="{item}">
                    <tr>
                      <td v-for="(col, columnIndex) in header.BEST" :key="columnIndex" class="text-center" style="height: 32px;">
                        <span v-if="col.text=='น้ำหนัก'" >{{item[col.value]}}</span>
                        <span v-else>{{item[col.value]}}</span>
                      </td>
                    </tr>
                  </template>
                  </v-data-table>
                </div>
              </div>

              <!-- ไปรษณีย์ EMS  -->
              <div v-if="i == 3" div style="break-after:page">
                <p class="title"> อัตราค่าบริการ ไปรษณีย์ EMS </p>
                <p class="subtitle-1 font-weight-bold mb-1">ค่าบริการการแพ็คสินค้า</p>
                <div v-if="PackCostFormat.ThaipostEMS.rdoPackCost==1">
                  <v-row>
                    <v-col cols="4">ราคาแพ็ค</v-col>
                    <v-col cols="3">{{PackCostFormat.ThaipostEMS.PackCost}}</v-col>
                  </v-row>
                  <div class="my-3">
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.ThaipostEMS.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div v-else-if="PackCostFormat.ThaipostEMS.rdoPackCost==2">
                  <v-data-table
                      :headers="headerBoxCreate"
                      :items="PackCostFormat.ThaipostEMS.Postbox"
                      :items-per-page="90000000000000000"
                      hide-default-footer
                      class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                      <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                      <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3}} </span>
                      </template>
                      <template v-slot:item.PackCost="{ item }">
                      <span >{{formatMoney(item.PackCost)}}</span>
                      </template>
                  </v-data-table>
                </div>
                <div v-else>
                  <v-row v-for="i in PackCostFormat.ThaipostEMS.FollowPicesPrice" :key="i.ID" row wrap class="packhai-card-padding py-1">
                    <v-col cols="4">
                      <span >ชิ้นที่ {{i.Start}} - {{i.End}}</span>
                    </v-col>
                    <v-col cols="3">
                      <span>ราคา {{i.Price}}</span>
                    </v-col>
                    <v-col cols="3">
                        <span>บาท/ชิ้น</span>
                      </v-col>
                  </v-row>
                  <div class="my-3">
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.ThaipostEMS.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div >
                  <p class="subtitle-1 font-weight-bold mt-4 mb-0"> COD </p>
                  <p v-if="formatCOD.ThaipostEMS == 0" class="text-left">ไม่อนุญาตส่งแบบ COD</p>
                  <div v-else class="text-left">
                    <p class="mb-0"> ค่าบริการ COD : {{TransportThaipostID3_COD_Remote_Return.ThaipostEMS_COD_Percent}} %</p>
                  </div>
                  <span style="color: red"> ** ยอดเงิน COD จะเข้าบัญชีไปที่ COD Wallet ซึ่งสามารถแจ้งถอนออกจากระบบได้ ภายใน 1-3 วันทำการ</span>
                </div>
                <p class="subtitle-1 font-weight-bold mt-3 mb-1">ค่าขนส่ง</p>
                <div>
                    <v-data-table
                    :headers="header.ThaipostEMS"
                    :items="DeliveryPice.ThaipostEMS"
                    :items-per-page="itemsPerPageฺThaipostEMS"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                  <template v-for="header in header.ThaipostEMS" v-slot:[`header.${header.value}`]="{ header }">
                    <v-tooltip :key="header.value" v-slot:activator="{ on }">
                      <span v-on="on">{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item="{item}">
                    <tr>
                      <td v-for="(col, columnIndex) in header.ThaipostEMS" :key="columnIndex" class="text-center" style="height: 32px;">
                        <span v-if="col.text=='น้ำหนัก'" >{{item[col.value]}}</span>
                        <span v-else>{{item[col.value]}}</span>
                      </td>
                    </tr>
                  </template>
                  </v-data-table>
                </div>
              </div>

              <!-- NIM EXPRESS -->
              <div v-if="i == 6" div style="break-after:page">
                <p class="title "> อัตราค่าบริการ NIM EXPRESS </p>
                <p class="subtitle-1 font-weight-bold mb-1" >ค่าบริการการแพ็คสินค้า</p>
                <div v-if="PackCostFormat.NIM.rdoPackCost==1">
                  <v-row>
                    <v-col cols="4">ราคาแพ็ค</v-col>
                    <v-col cols="3">{{PackCostFormat.NIM.PackCost}}</v-col>
                  </v-row>
                  <div class="my-3">
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.NIM.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div v-else-if="PackCostFormat.NIM.rdoPackCost==2">
                  <v-data-table
                    :headers="headerBoxCreate"
                    :items="PackCostFormat.NIM.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                    <template v-slot:item.Name="{ item }">
                      <span >{{item.Name}}</span>
                    </template>
                    <template v-slot:item.Size="{ item }">
                      <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                    </template>
                    <template v-slot:item.PackCost="{ item }">
                      <span >{{formatMoney(item.PackCost)}}</span>
                    </template>
                  </v-data-table>
                </div>
                <div class="my-3">
                  <v-row v-for="i in PackCostFormat.NIM.FollowPicesPrice" :key="i.ID" row wrap class="packhai-card-padding py-1">
                    <v-col cols="4">
                      <span >ชิ้นที่ {{i.Start}} - {{i.End}}</span>
                    </v-col>
                    <v-col cols="3">
                      <span>ราคา {{i.Price}}</span>
                    </v-col>
                    <v-col cols="3">
                        <span>บาท/ชิ้น</span>
                      </v-col>
                  </v-row>
                  <div>
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.NIM.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div >
                  <p class="subtitle-1 font-weight-bold mt-4 mb-0"> COD </p>
                  <p v-if="formatCOD.NIM == 0" class="text-left">ไม่อนุญาตส่งแบบ COD</p>
                  <div v-else class="text-left">
                    <p class="mb-0"> ค่าบริการ COD : {{TransportNIM_COD_Remote_Return.NIM_COD_Percent}} %</p>
                  </div>
                  <span style="color: red"> ** ยอดเงิน COD จะเข้าบัญชีไปที่ COD Wallet ซึ่งสามารถแจ้งถอนออกจากระบบได้ ภายใน 1-3 วันทำการ</span>
                </div>
                <p class="subtitle-1 font-weight-bold mt-3 mb-1">ค่าขนส่ง</p>
                <div>
                    <v-data-table
                    :headers="header.NIM"
                    :items="DeliveryPice.NIM"
                    :items-per-page="itemsPerPageฺNIM"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                  <template v-for="header in header.NIM" v-slot:[`header.${header.value}`]="{ header }" >
                    <v-tooltip :key="header.value" v-slot:activator="{ on }" >
                      <span v-on="on" >{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item="{item}">
                    <tr>
                      <td v-for="(col, columnIndex) in header.NIM" :key="columnIndex" class="text-center" style="height: 32px;">
                        <span v-if="col.text=='น้ำหนัก'" >{{item[col.value]}}</span>
                        <span v-else>{{item[col.value]}}</span>
                      </td>
                    </tr>
                  </template>
                  </v-data-table>
                </div>
              </div>

              <!-- FLASH EXPRESS -->
              <div v-if="i == 7" div style="break-after:page">
                <p class="title "> อัตราค่าบริการ FLASH EXPRESS </p>
                <p class="subtitle-1 font-weight-bold mb-1" >ค่าบริการการแพ็คสินค้า</p>
                <div v-if="PackCostFormat.FLASH.rdoPackCost==1">
                  <v-row>
                    <v-col cols="4">ราคาแพ็ค</v-col>
                    <v-col cols="3">{{PackCostFormat.FLASH.PackCost}}</v-col>
                  </v-row>
                  <div class="my-3">
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.FLASH.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div v-else-if="PackCostFormat.FLASH.rdoPackCost==2">
                  <v-data-table
                    :headers="headerBoxCreate"
                    :items="PackCostFormat.FLASH.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                    <template v-slot:item.Name="{ item }">
                      <span >{{item.Name}}</span>
                    </template>
                    <template v-slot:item.Size="{ item }">
                      <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                    </template>
                    <template v-slot:item.PackCost="{ item }">
                      <span >{{formatMoney(item.PackCost)}}</span>
                    </template>
                </v-data-table>
                </div>
                <div v-else>
                  <v-row v-for="i in PackCostFormat.FLASH.FollowPicesPrice" :key="i.ID" row wrap class="packhai-card-padding py-1">
                    <v-col cols="4">
                      <span >ชิ้นที่ {{i.Start}} - {{i.End}}</span>
                    </v-col>
                    <v-col cols="3">
                      <span>ราคา {{i.Price}}</span>
                    </v-col>
                    <v-col cols="3">
                        <span>บาท/ชิ้น</span>
                    </v-col>
                  </v-row>
                  <div class="my-3">
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.FLASH.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div >
                  <p class="subtitle-1 font-weight-bold mt-4 mb-0"> COD </p>
                  <p v-if="formatCOD.FLASH == 0" >ไม่อนุญาตส่งแบบ COD</p>
                  <div v-else >
                    <p class="mb-0"> ค่าบริการ COD : {{TransportFLASH_COD_Remote_Return.FLASH_COD_Percent}} %</p>
                  </div>
                  <span style="color: red"> ** ยอดเงิน COD จะเข้าบัญชีไปที่ COD Wallet ซึ่งสามารถแจ้งถอนออกจากระบบได้ ภายใน 1-3 วันทำการ</span>
                </div>
                <p class="subtitle-1 font-weight-bold mt-3 mb-1">ค่าขนส่ง</p>
                <div>
                    <v-data-table
                    :headers="header.FLASH"
                    :items="DeliveryPice.FLASH"
                    :items-per-page="itemsPerPageฺFLASH"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                  <template v-for="header in header.FLASH" v-slot:[`header.${header.value}`]="{ header }" >
                    <v-tooltip :key="header.value" v-slot:activator="{ on }" >
                      <span v-on="on" >{{ header.text }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item="{item}">
                    <tr>
                      <td v-for="(col, columnIndex) in header.FLASH" :key="columnIndex" class="text-center" style="height: 32px;">
                        <span v-if="col.text=='น้ำหนัก'" >{{item[col.value]}}</span>
                        <span v-else>{{item[col.value]}}</span>
                      </td>
                    </tr>
                  </template>
                  </v-data-table>
                </div>
              </div>

              <!-- KERRY EXPRESS -->
              <div v-if="i == 2" div style="break-after:page">
                <p class="title "> อัตราค่าบริการ KERRY EXPRESS </p>
                <p class="subtitle-1 font-weight-bold mb-1" >ค่าบริการการแพ็คสินค้า</p>
                <div v-if="PackCostFormat.KERRY.rdoPackCost==1">
                  <v-row>
                    <v-col cols="4">ราคาแพ็ค</v-col>
                    <v-col cols="3">{{PackCostFormat.KERRY.PackCost}}</v-col>
                  </v-row>
                  <div class="my-3">
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.KERRY.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                  <div>
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.KERRY.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div v-else-if="PackCostFormat.KERRY.rdoPackCost==2">
                  <v-data-table
                    :headers="headerBoxCreate"
                    :items="PackCostFormat.KERRY.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                    <template v-slot:item.Name="{ item }">
                      <span >{{item.Name}}</span>
                    </template>
                    <template v-slot:item.Size="{ item }">
                      <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                    </template>
                    <template v-slot:item.PackCost="{ item }">
                      <span >{{formatMoney(item.PackCost)}}</span>
                    </template>
                </v-data-table>
                </div>
                <div v-else>
                  <v-row v-for="i in PackCostFormat.KERRY.FollowPicesPrice" :key="i.ID" row wrap class="packhai-card-padding py-1">
                    <v-col cols="4">
                      <span >ชิ้นที่ {{i.Start}} - {{i.End}}</span>
                    </v-col>
                    <v-col cols="4">
                      <span>ราคา {{i.Price}}</span>
                    </v-col>
                    <v-col cols="3">
                        <span>บาท/ชิ้น</span>
                      </v-col>
                  </v-row>
                  <div class="my-3">
                    <v-data-table
                    :headers="headerBoxPrice"
                    :items="PackCostFormat.KERRY.Postbox"
                    :items-per-page="90000000000000000"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                      <template v-slot:item.Name="{ item }">
                        <span >{{item.Name}}</span>
                      </template>
                      <template v-slot:item.Size="{ item }">
                        <span >{{item.Cubic1 + " x "}} {{item.Cubic2 + " x "}} {{item.Cubic3 }} </span>
                      </template>
                      <template v-slot:item.Price="{ item }">
                        <span >{{item.Price ==null || item.Price =="" ? "0.00 " : formatMoney(item.Price)}}</span>
                      </template>
                    </v-data-table>
                  </div>
                </div>
                <div >
                  <p class="subtitle-1\ font-weight-bold mt-4 mb-0"> COD </p>
                  <p v-if="formatCOD.KERRY == 0" >ไม่อนุญาตส่งแบบ COD</p>
                  <div v-else >
                    <p class="mb-0"> ค่าบริการ COD : {{TransportKERRY_COD_Remote_Return.KERRY_COD_Percent}} %</p>
                  </div>
                  <span style="color: red"> ** ยอดเงิน COD จะเข้าบัญชีไปที่ COD Wallet ซึ่งสามารถแจ้งถอนออกจากระบบได้ ภายใน 1-3 วันทำการ</span>
                </div>
                <p class="subtitle-1 font-weight-bold mt-3 mb-1">ค่าขนส่ง</p>
                <div>
                    <v-data-table
                    :headers="header.KERRY"
                    :items="DeliveryPice.KERRY"
                    :items-per-page="itemsPerPageฺKERRY"
                    hide-default-footer
                    class="packhai-border-table-quaotation tr td"
                  >
                    <template v-for="header in header.KERRY" v-slot:[`header.${header.value}`]="{ header }">
                      <v-tooltip :key="header.value" v-slot:activator="{ on }">
                        <span v-on="on">{{ header.text }}</span>
                      </v-tooltip>
                    </template>
                    <template v-slot:item="{item}">
                      <tr>
                        <td v-for="(col, columnIndex) in header.ThaipostEMS" :key="columnIndex" class="text-center" style="height: 32px;">
                          <span v-if="col.text=='น้ำหนัก'" >{{item[col.value]}}</span>
                          <span v-else>{{item[col.value]}}</span>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </div>
              </div>

              <!-- <div v-if="i == 10" div style="break-after:page">
                <p class="title "> อัตราค่าบริการ SCG EXPRESS </p>
              </div>
              <div v-if="i == 11" div style="break-after:page">
                <p class="title "> อัตราค่าบริการ JT EXPRESS </p>
              </div> -->
            </div>

            <!-- AdditionalCondition -->
            <div div v-if="AdditionalCondition != null">
              <p class="title text-left">เงื่อนไขเพิ่มเติม</p>
              <p class="text-left">{{AdditionalCondition}}</p>
            </div>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { manageShopService, selectService, generalService_dotnet } from '@/website/global'
  import { isNumberWithDot, isNumberWNoDot, formatMoney} from '@/website/global_function'
  export default {
    data: () => ({
      page_loading: true,
      loading: false,
      ShopID: 0,
      // ประเภทลูกค้า แพ็คให้ แพ็คเอง
      CustomerType: 1,
      CustomerTypeItem: [{ "label": "ใช้บริการ Fulfillment + ระบบจัดการออเดอร์", "value": 1 }, { "label": "ใช้บริการแค่ระบบจัดการออเดอร์", "value": 2 }],

      // ประเภทใบแจ้งหนี้ อัตโนมัติ ปกติ
      AutoInvoice: 1,
      AutoInvoiceItem: [{ "label": "อัตโนมัติ", "value": 1 }, { "label": "ต้องการสร้างใบแจ้งหนี้เอง", "value": 0 }],

      // BalanceLimit
      rdoMinimumCredit: [{ "label": "ไม่มีขั้นต่ำ", "value": 0 }, { "label": "มีขั้นต่ำ", "value": 1 }],
      MinimumCredit: 0,
      BalanceLimit: parseFloat(0).toFixed(2), //แพ็คสิรค้าได้ Balance ไม่ต้ำกว่า BalanceLimit

      // เปิดออเดอร์ อนุญาติให้เปิดออเดอร์ได้สต๊อก=0
      OrderActiveLessthanzero: 0,
      OrderActiveLessthanzeroItem: [{ "label": "ไม่ให้เปิดออเดอร์ถ้าสต๊อกสินค้าไม่เพียงพอ", "value": 0 }, { "label": "เปิดออเดอร์ได้ในกรณีสต๊อกไม่เพียงพอ", "value": 1 }],

      AddressPrintType: 2,
      AddressPrintType_list: [
          { "value": 1 , "label": "ใช้ PACKHAI Fulfillment" },
          { "value": 2 , "label": "ใช้ชื่อลูกค้าแต่ที่อยู่ของ PACKHAI Fulfillment" },
          { "value": 3 , "label": "ใช้ชื่อของร้านค้าและที่อยู่ของร้านค้า" }
      ],

      PriceQuotationActive: 1,
      PriceQuotation_list: [
          {"label": "ไม่สร้างใบเสนอราคา", "value": 1},
          {"label": "สร้างใบเสนอราคา", "value": 0}
      ],

      addFCName: '',
      addFCUserName: '',
      addFCTel: '',
      addFCUserTel: '',
      rulesTel: [],
      addFCEmail: '',
      addFCAddress: '',
      ShopAddressID: null,
      addFCAddressCity: '',
      postcode_mapping: null,
      username: '',
      password: '',
      TabTransport: null,
      itemsTabTransport: [],
      selectedTransport: [1,8,9],
      rdoTransportItems: [],

      // branch
      Namebranch:'',
      addressbranch: '',
      branchtextaddress:'',
      telbranch:'',
      // ข้อมูล
      ProvinceGroup: [],
      DeliveryPice: [],
      DeliveryPiceChange: null,

      // แสดงข้อมูล
      header: null,

      TransportBEST_COD_Remote_Return: {
          'BEST_COD_Percent': null,
          'BEST_Remote_Area_Discount_Percent': null,
          'BEST_Return_Delivery_Discount_Percent': null,
          'BEST_Return_COD_Discount_Percent': null,
          'BEST_Return_BoxPrice_Discount_Percent': null,
          'BEST_Return_PackPrice_Discount_Percent': null
      },
      TransportKERRY_COD_Remote_Return: {
          'KERRY_COD_Percent': null,
          'KERRY_Remote_Area_Discount_Percent': null,
          'KERRY_Return_Delivery_Discount_Percent': null,
          'KERRY_Return_COD_Discount_Percent': null,
          'KERRY_Return_BoxPrice_Discount_Percent': null,
          'KERRY_Return_PackPrice_Discount_Percent': null
      },
      TransportNIM_COD_Remote_Return: {
          'NIM_COD_Percent': null,
          'NIM_Remote_Area_Discount_Percent': null,
          'NIM_Return_Delivery_Discount_Percent': null,
          'NIM_Return_COD_Discount_Percent': null,
          'NIM_Return_BoxPrice_Discount_Percent': null,
          'NIM_Return_PackPrice_Discount_Percent': null
      },
      TransportFLASH_COD_Remote_Return: {
          'FLASH_COD_Percent': null,
          'FLASH_Remote_Area_Discount_Percent': null,
          'FLASH_Return_Delivery_Discount_Percent': null,
          'FLASH_Return_COD_Discount_Percent': null,
          'FLASH_Return_BoxPrice_Discount_Percent': null,
          'FLASH_Return_PackPrice_Discount_Percent': null
      },
      TransportSCG_COD_Remote_Return: {
          'SCG_COD_Percent': null,
          'SCG_Remote_Area_Discount_Percent': null,
          'SCG_Return_Delivery_Discount_Percent': null,
          'SCG_Return_COD_Discount_Percent': null,
          'SCG_Return_BoxPrice_Discount_Percent': null,
          'SCG_Return_PackPrice_Discount_Percent': null
      },
      headerTransportThaipostID3: [
          { align: 'center', text: 'น้ำหนัก', value: 'Weight' },
          { align: 'center', text: 'ราคา', value: 'Price' },
      ],
      TransportThaipostID3: [],
      TransportThaipostID3_COD_Remote_Return: {
          'ThaipostEMS_COD_Percent': null,
          'ThaipostEMS_Remote_Area_Discount_Percent': null,
          'ThaipostEMS_Return_Delivery_Discount_Percent': null,
          'ThaipostEMS_Return_COD_Discount_Percent': null,
          'ThaipostEMS_Return_BoxPrice_Discount_Percent': null,
          'ThaipostEMS_Return_PackPrice_Discount_Percent': null
      },
      headerTransportThaipostID4: [
          { align: 'center', text: 'น้ำหนัก', value: 'Weight' },
          { align: 'center', text: 'ราคา', value: 'Price' },
      ],
      TransportThaipostID4: [],

      // ตั้งค่าค่าบริการแพ็คสินค้า
      PackCost: parseFloat(0).toFixed(2),
      rulesEnd: [],
      PackCostFormat: [],
      headerBoxCreate: [
          { align: 'left', text: 'กล่อง', value: 'Name', sortable: false},
          { align: 'center', text: 'ขนาด ', value: 'Size', sortable: false},
          { align: 'center', text: 'ค่ากล่อง+ค่าแพ็ค', value: 'PackCost', sortable: false },
      ],

      headerBoxPrice: [
          { align: 'left', text: 'กล่อง', value: 'Name', sortable: false},
          { align: 'center', text: 'ขนาด ', value: 'Size', sortable: false},
          { align: 'center', text: 'ค่ากล่อง', value: 'Price', sortable: false},
      ],

      // ส่งแบบ COD :
      formatCOD: {
          "BEST":1,
          "KERRY":1,
          "ThaipostEMS":1,
          "ThaipostRegister":1,
          "ThaipostNormal":1,
          "NIM":1,
          "FLASH":1,
          "SHOPEE":1,
          "LAZADA":1,
          "SCG":1,
          "J&T":1,
      },
      formatCODdetail: [{ "label": "อนุญาต", "value": 1 }, { "label":"ไม่อนุญาต", "value": 0 }],

      // ออกใบกำกับภาษี
      ShopAddressInvoice: {
          "ID":null,
          "Name": null,
          "Phone": null,
          "IDCardNumber": null,
          "TextAddress": null,
          "Address": null,
          "PostcodeMappingID":null,
      },

      // ค่าบริการรายเดือน
      startDate: new Date().toISOString().substr(0, 10),
      menuStartDate: false,
      checkStart: true,

      endDate: null,
      menuEndDate: false,
      checkEnd: true,

      DialogShopMonthlyFee: false,
      ShopMonthlyFee: {
          "pUpdate": false,
          "ID":null,
          "ServiceCharge": null,
          "StartDate": null,
          "EndDate": null,
          "CheckEndDate": [
          { "value": 0 , "label": "ไม่มีสิ้นสุด" },
          { "value": 1 , "label": "สิ้นสุดวันที่" },
          ],
          "SelectEndDate": 0,
          "CreateInvoiceEveryDay": null,
          "CreateInvoiceEveryDay_List": [],
          "Price": null,
          "rulesServiceCharge": [],
          "rulesStartDate": [],
          "rulesCreateInvoiceEveryDay": [],
          "rulesPrice": [],
      },
      // แสดงข้อมูล
      headerShopMonthlyFee: [
          { align: 'center', text: 'No', value: 'No', sortable: false, width: '70px'  },
          { align: 'left', text: 'ค่าบริการ', value: 'ServiceCharge', sortable: false  },
          { align: 'center', text: 'วันที่เริ่ม', value: 'StartDate', sortable: false, width: '130px' },
          { align: 'center', text: 'วันที่สิ้นสุด', value: 'EndDate', sortable: false, width: '130px' },
          { align: 'center', text: 'วันที่สร้างบิล', value: 'CreateInvoiceEveryDay', sortable: false, width: '130px' },
          { align: 'right', text: 'ยอด', value: 'Price', sortable: false, width: '100px' }
      ],
      dataShopMonthlyFee: [],
      // ค่าบริการรายเดือน

      AdditionalCondition: null
    }),
    computed: {
      itemsPerPageฺBEST() {
        return this.DeliveryPice.BEST.length
      },
      itemsPerPageฺKERRY() {
        return this.DeliveryPice.KERRY.length
      },
      itemsPerPageฺThaipostEMS() {
        return this.DeliveryPice.ThaipostEMS.length
      },
      itemsPerPageฺNIM() {
        return this.DeliveryPice.NIM.length
      },
      itemsPerPageฺFLASH() {
        return this.DeliveryPice.FLASH.length
      },
    },
    async created(){
      this.ShopID = localStorage.getItem('CreateShop_ShopID')
      await this.get_postcode_mapping()
      let response = await axios.post(selectService+'select/SettingStartPage', {
        'BranchID': localStorage.getItem('Branch_BranchID')
      }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
      if (response.data.status == 'Success') {
      // ข้อมูลสาขา
        this.Namebranch = response.data.Branch.name
        this.branchtextaddress = response.data.Branch.Address
        for (var i in this.postcode_mapping) {
          if (this.postcode_mapping[i]["Id"] == response.data.Branch.PostcodeMappingID){
            this.addressbranch = {
              "id": this.postcode_mapping[i]["Id"],
              "district": this.postcode_mapping[i]["SubDistrict"],
              "amphoe": this.postcode_mapping[i]["District"],
              "province": this.postcode_mapping[i]["Province"],
              "zipcode": this.postcode_mapping[i]["Postcode"],
              "provinceid": this.postcode_mapping[i]["ProvinceId"]
            }
          }
        }
        this.telbranch = response.data.Branch.PhoneNumber
      }
      await this.openPageCreateShopEdit()
      this.page_loading = false
      print()
    },
    methods: {
      async openPageCreateShopEdit(){
        this.loading = true
        let response = await axios.post(manageShopService+'manageShop/getShopEdit', {
        'ShopID': this.ShopID
        }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
        if (response.data.status == 'Success' && response.data.authorized!=0) {
        // แก้ไขร้านค้า
        this.addFCName = response.data.resultShop['Name']
        this.addFCTel = response.data.resultShop['Phone']
        this.addFCEmail = response.data.resultShop['Email']
        this.addFCAddress = response.data.resultShop['Address']
        this.ShopAddressID = response.data.resultShop['ShopAddressID']
        for (var p in this.postcode_mapping){
            if (this.postcode_mapping[p]["Id"]==response.data.resultShop['PostcodeMappingID']){
            this.addFCAddressCity = {
                "id": this.postcode_mapping[p]["Id"],
                "district": this.postcode_mapping[p]["SubDistrict"],
                "amphoe": this.postcode_mapping[p]["District"],
                "province": this.postcode_mapping[p]["Province"],
                "zipcode": this.postcode_mapping[p]["Postcode"],
                "provinceid": this.postcode_mapping[p]["ProvinceId"]
            }
            }
        }

        // ที่อยู่ในการออกใบกำกับภาษี
        if (response.data.resultShopAddressInvoice.length>0){
            var ShopAddressInvoice = response.data.resultShopAddressInvoice[0]
            this.ShopAddressInvoice.ID = ShopAddressInvoice.ID
            this.ShopAddressInvoice.Name = ShopAddressInvoice.Name
            this.ShopAddressInvoice.Phone = ShopAddressInvoice.Phone
            this.ShopAddressInvoice.IDCardNumber = ShopAddressInvoice.IDCardNumber
            this.ShopAddressInvoice.TextAddress = ShopAddressInvoice.Address
            for (var pm in this.postcode_mapping){
            if (this.postcode_mapping[pm]["Id"]==ShopAddressInvoice['PostcodeMappingID']){
                this.ShopAddressInvoice.Address = {
                "id": this.postcode_mapping[pm]["Id"],
                "district": this.postcode_mapping[pm]["SubDistrict"],
                "amphoe": this.postcode_mapping[pm]["District"],
                "province": this.postcode_mapping[pm]["Province"],
                "zipcode": this.postcode_mapping[pm]["Postcode"],
                "provinceid": this.postcode_mapping[pm]["ProvinceId"]
                }
            }
            }
        }

        // ค่าบริการรายเดือน
        this.dataShopMonthlyFee = response.data.resultShopMonthlyFee
        this.ShopMonthlyFee.CreateInvoiceEveryDay_List = response.data.CreateInvoiceEveryDay_List

        // ประเภทลูกค้า
        this.CustomerType=response.data.resultShop['CustomerType']

        // BalanceLimit
        if (response.data.resultShop['BalanceLimit']!=null){
            this.BalanceLimit = parseFloat(response.data.resultShop['BalanceLimit']).toFixed(2)
            this.MinimumCredit = 1
        } else {
            this.MinimumCredit = 0
        }


        // สร้างออเดอร์
        this.OrderActiveLessthanzero = response.data.resultShop['IsAllowAddOrderWhenStockBranchNotAvailable']
        if (this.OrderActiveLessthanzero!=0 && this.OrderActiveLessthanzero!=1){this.OrderActiveLessthanzero=0}

        // ใบปะหน้า
        this.AddressPrintType = response.data.resultShop['AddressPrintType']

        // การสร้างใบแจ้งหนี้
        this.AutoInvoice=response.data.resultShop['AutoInvoice']

        // เรียก herder DeliveryPiceChange
        this.datatable()

        // เรียกขนส่ง
        this.rdoTransportItems = response.data.resultTransport
        this.ProvinceGroup = response.data.ProvinceGroup

        // ขนส่งที่เลือก
        this.selectedTransport = response.data.TransportSelectMaster


        // ส่งแบบ COD
        for (var a=0; a<response.data.resultIsAllowCOD.length; a++){
            if (response.data.resultIsAllowCOD[a]['ExpressCompanyID']==1){
            this.formatCOD.BEST = response.data.resultIsAllowCOD[a]['IsAllowCOD']
            }
            if (response.data.resultIsAllowCOD[a]['ExpressCompanyID']==2){
            this.formatCOD.KERRY = response.data.resultIsAllowCOD[a]['IsAllowCOD']
            }
            if (response.data.resultIsAllowCOD[a]['ExpressCompanyID']==3){
            this.formatCOD.ThaipostEMS = response.data.resultIsAllowCOD[a]['IsAllowCOD']
            }
            if (response.data.resultIsAllowCOD[a]['ExpressCompanyID']==6){
            this.formatCOD.NIM = response.data.resultIsAllowCOD[a]['IsAllowCOD']
            }
            if (response.data.resultIsAllowCOD[a]['ExpressCompanyID']==7){
            this.formatCOD.FLASH = response.data.resultIsAllowCOD[a]['IsAllowCOD']
            }
        }

        // สร้าง header
        this.genHerder(this.rdoTransportItems,this.ProvinceGroup,this.header)
        // รับข้อมูล datatable
        this.DeliveryPice = response.data.DeliveryPice

        // ตั้งค่าค่าบริการแพ็คสินค้า

        // แสดงราคาคงที่ // แสดงราคาตามกล่อง
        this.PackCostFormat = response.data.PackCostFormat
        this.PackCostFormat.BEST.PackCost = parseFloat(this.PackCostFormat.BEST.PackCost).toFixed(2)
        this.PackCostFormat.KERRY.PackCost = parseFloat(this.PackCostFormat.KERRY.PackCost).toFixed(2)
        this.PackCostFormat.ThaipostEMS.PackCost = parseFloat(this.PackCostFormat.ThaipostEMS.PackCost).toFixed(2)
        this.PackCostFormat.ThaipostRegister.PackCost = parseFloat(this.PackCostFormat.ThaipostRegister.PackCost).toFixed(2)
        this.PackCostFormat.ThaipostNormal.PackCost = parseFloat(this.PackCostFormat.ThaipostNormal.PackCost).toFixed(2)
        this.PackCostFormat.NIM.PackCost = parseFloat(this.PackCostFormat.NIM.PackCost).toFixed(2)
        this.PackCostFormat.FLASH.PackCost = parseFloat(this.PackCostFormat.FLASH.PackCost).toFixed(2)
        this.PackCostFormat.SHOPEE.PackCost = parseFloat(this.PackCostFormat.SHOPEE.PackCost).toFixed(2)
        this.PackCostFormat.LAZADA.PackCost = parseFloat(this.PackCostFormat.LAZADA.PackCost).toFixed(2)
        // set ค่าบริการการเชื่อมต่อ
        this.PackCostFormat.SHOPEE.FirstShopFee = parseFloat(this.PackCostFormat.SHOPEE.FirstShopFee).toFixed(2)
        this.PackCostFormat.SHOPEE.SecondShopFee = parseFloat(this.PackCostFormat.SHOPEE.SecondShopFee).toFixed(2)
        this.PackCostFormat.LAZADA.FirstShopFee = parseFloat(this.PackCostFormat.LAZADA.FirstShopFee).toFixed(2)
        this.PackCostFormat.LAZADA.SecondShopFee = parseFloat(this.PackCostFormat.LAZADA.SecondShopFee).toFixed(2)

        // ตั้งค่าส่วนลด ในกรณีสินค้าตีกลับ
        // COD%
        // BEST
        this.TransportBEST_COD_Remote_Return.BEST_COD_Percent = parseFloat(response.data.resultShop['BEST_COD_Percent']).toFixed(2)

        // KERRY
        this.TransportKERRY_COD_Remote_Return.KERRY_COD_Percent = parseFloat(response.data.resultShop['KERRY_COD_Percent']).toFixed(2)

        // ThaipostEMS
        this.TransportThaipostID3_COD_Remote_Return.ThaipostEMS_COD_Percent = parseFloat(response.data.resultShop['ThaipostEMS_COD_Percent']).toFixed(2)

        // NIM
        this.TransportNIM_COD_Remote_Return.NIM_COD_Percent = parseFloat(response.data.resultShop['NIM_COD_Percent']).toFixed(2)

        // FLASH
        this.TransportFLASH_COD_Remote_Return.FLASH_COD_Percent = parseFloat(response.data.resultShop['FLASH_COD_Percent']).toFixed(2)

        // Return...
        for (var i in response.data.Shop_Express){
            // BEST
            if (response.data.Shop_Express[i]['ExpressCompanyID']==1){
            this.TransportBEST_COD_Remote_Return.BEST_Remote_Area_Discount_Percent = response.data.Shop_Express[i]['RemoteAreaDiscountPercent']
            this.TransportBEST_COD_Remote_Return.BEST_Return_Delivery_Discount_Percent = response.data.Shop_Express[i]['ReturnDeliveryDiscountPercent']
            this.TransportBEST_COD_Remote_Return.BEST_Return_COD_Discount_Percent = response.data.Shop_Express[i]['ReturnCODDiscountPercent']
            this.TransportBEST_COD_Remote_Return.BEST_Return_BoxPrice_Discount_Percent = response.data.Shop_Express[i]['ReturnBoxPriceDiscountPercent']
            this.TransportBEST_COD_Remote_Return.BEST_Return_PackPrice_Discount_Percent = response.data.Shop_Express[i]['ReturnPackPriceDiscountPercent']
            }
        }
        // IsActive
        this.PriceQuotationActive = response.data.resultShop['IsActive']
        this.AdditionalCondition = response.data.resultShop['AdditionalCondition']
        }
        this.loading = false
      },
      genHerder(ExpressCompany,ProvinceGroup,header){
        for (var a=0; a<ExpressCompany.length; a++){
          var ExpressCompanyID = ExpressCompany[a]['value']
          if (ExpressCompanyID==1){
            header.BEST.push({ align: 'center', text: 'น้ำหนัก', sortable: false, value: 'ConstantWeight'})
            for (var i=0; i<ProvinceGroup.length; i++){
              if (ProvinceGroup[i]['ExpressCompanyID']==1){
                header.BEST.push({
                  'align': 'center',
                  'text': ProvinceGroup[i]['Description'],
                  'sortable': false,
                  'value': ProvinceGroup[i]['Description'],
                  'ProvinceGroupID': ProvinceGroup[i]['ID']
                })
              }
            }
          }else if (ExpressCompanyID==2){
            header.KERRY.push({ align: 'center', text: 'น้ำหนัก', sortable: false, value: 'ConstantWeight'})
            for (var e=0; e<ProvinceGroup.length; e++){
              if (ProvinceGroup[e]['ExpressCompanyID']==2){
                header.KERRY.push({
                  'align': 'center',
                  'text': ProvinceGroup[e]['Description'],
                  'sortable': false,
                  'value': ProvinceGroup[e]['Description'],
                  'ProvinceGroupID': ProvinceGroup[e]['ID']
                })
              }
            }
          }else if (ExpressCompanyID==3){
            header.ThaipostEMS.push({ align: 'center', text: 'น้ำหนัก', sortable: false, value: 'ConstantWeight'})
            for (var o=0; o<ProvinceGroup.length; o++){
              if (ProvinceGroup[o]['ExpressCompanyID']==3){
                header.ThaipostEMS.push({
                  'align': 'center',
                  'text': ProvinceGroup[o]['Description'],
                  'sortable': false,
                  'value': ProvinceGroup[o]['Description'],
                  'ProvinceGroupID': ProvinceGroup[o]['ID']
                })
              }
            }
          }else if (ExpressCompanyID==6){
            header.NIM.push({ align: 'center', text: 'น้ำหนัก', sortable: false, value: 'ConstantWeight'})
            for (var u=0; u<ProvinceGroup.length; u++){
              if (ProvinceGroup[u]['ExpressCompanyID']==6){
                header.NIM.push({
                  'align': 'center',
                  'text': ProvinceGroup[u]['Description'],
                  'sortable': false,
                  'value': ProvinceGroup[u]['Description'],
                  'ProvinceGroupID': ProvinceGroup[u]['ID']
                })
              }
            }
          }else if (ExpressCompanyID==7){
            header.FLASH.push({ align: 'center', text: 'น้ำหนัก', sortable: false, value: 'ConstantWeight'})
            for (var j=0; j<ProvinceGroup.length; j++){
              if (ProvinceGroup[j]['ExpressCompanyID']==7){
                header.FLASH.push({
                  'align': 'center',
                  'text': ProvinceGroup[j]['Description'],
                  'sortable': false,
                  'value': ProvinceGroup[j]['Description'],
                  'ProvinceGroupID': ProvinceGroup[j]['ID']
                })
              }
            }
          }
        }
      },
      datatable(){
        this.header = {
          "BEST":[],
          "KERRY":[],
          "ThaipostEMS":[],
          "ThaipostRegister":[],
          "ThaipostNormal":[],
          "NIM":[],
          "FLASH":[],
        }
        this.DeliveryPiceChange = {
          "BEST":[],
          "KERRY":[],
          "ThaipostEMS":[],
          "ThaipostRegister":[],
          "ThaipostNormal":[],
          "NIM":[],
          "FLASH":[],
        }
      },
      async get_postcode_mapping () {
        let response = await axios.post(generalService_dotnet+'General/get-postcodeMapping', {},{headers: header_token})
        if (response.status == 200) { this.postcode_mapping = response.data }
      },
      isNumberWithDot,
      isNumberWNoDot,
      formatMoney,
    },
  }
</script>

<style scoped>
  ::v-deep .v-data-table-header {
    background-color: rgb(241, 44, 9);
  }
  ::v-deep th {
    color: rgb(252, 252, 252) !important;
    font-size: 11px !important;
  }
  .title{
    color: black;
  }
</style>
